body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  height: 100%;
}

::-webkit-scrollbar-track {
  background: #fafafa;
  border: solid 1px #ececec;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #c2c2c2;
}
::-webkit-scrollbar-thumb:hover {
  background: #868686;
}

@media print {
  .pdfPageDivider {
    display: none !important;
  }
}
