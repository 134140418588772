.importModal {
  color: #3c3c3c;
  text-align: center;
  :global {
    .ant-modal-body {
      padding: 46px 74px 31px;
    }
    .ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
      margin-top: 11px;
    }
    .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
      top: 3px;
      left: -7px;
      padding: 14px 0 0;
    }
    .ant-steps-dot .ant-steps-item-icon {
      width: 11px;
      height: 11px;
    }
    .ant-steps-icon,
    .ant-steps-item-title {
      font-weight: 600;
    }
    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title,
    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description,
    .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
      color: #3c3c3c;
    }
  }
}

.downloadTemplateWrapper {
  display: flex;
  flex-direction: row;
  padding: 13px 0 7px;
  .downloadTemplateBtn {
    width: 239px;
    height: 72px;
    border-radius: 4px;
    margin-right: 11px;
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
  }
}

@font-size-base: 14px;@font-family: Inter;@primary-color: #008060;@danger-color: #d61818;@text-color: #3c3c3c;@border-color-base: #d8d8d8;@background-color-base: #f8f8f8;@disabled-bg: @background-color-base;@layout-header-background: @primary-color;@layout-body-background: #f8f8f8;@menu-dark-bg: @primary-color;@menu-dark-item-color: @text-color;@menu-dark-highlight-color: #fff;@modal-header-bg: @primary-color;@modal-heading-color: #fff;@modal-close-color: #fff;@modal-header-padding-horizontal: 16px;@table-row-hover-bg: #cce6df;@table-selected-row-bg: #cce6df;@table-header-bg: @background-color-base;@table-header-color: @text-color;@tabs-card-head-background: @background-color-base;@tabs-card-height: 48px;@tabs-card-gutter: 8px;@tooltip-bg: #fff;@tooltip-color: @text-color;@tooltip-max-width: 400px;@line-height-base: 1.75;@heading-1-size: 81px;@heading-2-size: 21px;@heading-3-size: 18px;@heading-4-size: 16px;@modal-footer-border-style: none;@tag-font-size: @font-size-base;@page-header-padding-vertical: 0;@page-header-padding: 0;@page-header-heading-title: 14px;