.before-upload-wrapper {
  height: 100%;
  :global {
    .ant-upload.ant-upload-drag .ant-upload {
      padding: 26px 0 29px;
    }
    .ant-upload.ant-upload-drag .ant-upload-drag-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 120px 0 115px;
    }
  }
}

.description {
  padding-top: 10px;
  line-height: 1.07;
  text-align: center;
}

.or {
  padding: 19px 0 10px;
  margin-bottom: 0;
  line-height: 1.07;
}

.error-message {
  font-size: 14px;
  font-weight: 300;
  color: #ed5c5c;
}

.upload-btn {
  width: 139px;
  height: 30px;
}

.after-upload-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 485px;
  height: 212px;
  padding: 16px 40px 16px 40px;
  border: solid 1px #d8d8d8;
  border-radius: 4px;
  text-align: center;
  :global {
    .ant-upload.ant-upload-drag {
      width: 385px;
      height: 92px;
      margin: auto;
      padding: 26px 30px;
      text-align: left;
    }
    .ant-btn {
      width: 69px;
      height: 30px;
      margin-left: 10px;
    }
    .ant-upload.ant-upload-drag .ant-upload {
      padding: 0;
    }
    .ant-upload.ant-upload-drag .ant-upload-drag-container {
      display: flex;
      flex-direction: row;
    }
    .ant-upload-list-item {
      display: none;
    }
  }

  .title {
    padding-bottom: 13px;
    line-height: 1.07;
  }
  .button-wrapper {
    position: absolute;
    bottom: 16px;
    right: 40px;
  }
  .drag-file {
    height: 50px;
    width: 40px;
  }
  .drag-text {
    margin-left: 17px;
    align-self: center;
    word-break: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

@font-size-base: 14px;@font-family: Inter;@primary-color: #008060;@danger-color: #d61818;@text-color: #3c3c3c;@border-color-base: #d8d8d8;@background-color-base: #f8f8f8;@disabled-bg: @background-color-base;@layout-header-background: @primary-color;@layout-body-background: #f8f8f8;@menu-dark-bg: @primary-color;@menu-dark-item-color: @text-color;@menu-dark-highlight-color: #fff;@modal-header-bg: @primary-color;@modal-heading-color: #fff;@modal-close-color: #fff;@modal-header-padding-horizontal: 16px;@table-row-hover-bg: #cce6df;@table-selected-row-bg: #cce6df;@table-header-bg: @background-color-base;@table-header-color: @text-color;@tabs-card-head-background: @background-color-base;@tabs-card-height: 48px;@tabs-card-gutter: 8px;@tooltip-bg: #fff;@tooltip-color: @text-color;@tooltip-max-width: 400px;@line-height-base: 1.75;@heading-1-size: 81px;@heading-2-size: 21px;@heading-3-size: 18px;@heading-4-size: 16px;@modal-footer-border-style: none;@tag-font-size: @font-size-base;@page-header-padding-vertical: 0;@page-header-padding: 0;@page-header-heading-title: 14px;