.layout {
  min-height: 100%;

  > header {
    position: fixed;
    z-index: 1;
    width: 100%;
  }
  > main {
    margin-top: 64px;
  }

  .logo {
    width: 120px;
    height: 64px;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding: 10px;

    a {
      display: inline-flex;
    }
  }

  .footer {
    margin-top: 12px;
    background: #ffffff;
    padding: 8px 20px;
    text-align: center;
    font-size: 12px;
  }

  .header {
    font-size: 16px;
    display: flex;
    > ul {
      flex: 1;
    }

    :global {
      .ant-menu-item,
      .ant-menu-submenu-title {
        span,
        a {
          font-size: 16px;
          display: flex;
          align-items: center;

          svg {
            margin-right: 4px;
          }
        }
      }
    }

    .supportItems {
      display: flex;
      align-items: center;
      margin: 0 20px;

      :global {
        svg {
          cursor: pointer;
          margin: 0 8px;
        }
      }
    }

    .profile {
      cursor: pointer;
      float: right;
      color: white;
    }
  }
}

:global {
  .ant-menu-submenu {
    .ant-menu-submenu-title {
      &:hover {
        color: white !important;
      }
      color: rgba(255, 255, 255, 0.65) !important;
      svg {
        vertical-align: middle;
      }
    }
  }
}

@font-size-base: 14px;@font-family: Inter;@primary-color: #008060;@danger-color: #d61818;@text-color: #3c3c3c;@border-color-base: #d8d8d8;@background-color-base: #f8f8f8;@disabled-bg: @background-color-base;@layout-header-background: @primary-color;@layout-body-background: #f8f8f8;@menu-dark-bg: @primary-color;@menu-dark-item-color: @text-color;@menu-dark-highlight-color: #fff;@modal-header-bg: @primary-color;@modal-heading-color: #fff;@modal-close-color: #fff;@modal-header-padding-horizontal: 16px;@table-row-hover-bg: #cce6df;@table-selected-row-bg: #cce6df;@table-header-bg: @background-color-base;@table-header-color: @text-color;@tabs-card-head-background: @background-color-base;@tabs-card-height: 48px;@tabs-card-gutter: 8px;@tooltip-bg: #fff;@tooltip-color: @text-color;@tooltip-max-width: 400px;@line-height-base: 1.75;@heading-1-size: 81px;@heading-2-size: 21px;@heading-3-size: 18px;@heading-4-size: 16px;@modal-footer-border-style: none;@tag-font-size: @font-size-base;@page-header-padding-vertical: 0;@page-header-padding: 0;@page-header-heading-title: 14px;